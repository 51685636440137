.App {
  display: flex;
}

.site-layout .site-layout-background {
  background: #444;
}

.logo {
  height: 32px;
  margin: 16px;
}

.ant-layout-sider-light .ant-menu-light .ant-menu-item-selected {
  background-color: #e6f7ff;
}

.ant-layout-sider-light .ant-menu-light .ant-menu-item:hover {
  background-color: #444;
}

.ant-layout-sider-light .ant-menu-light .ant-menu-item {
  padding-left: 24px;
}

.site-layout .ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background: #444;
  border-bottom: 1px solid #e8e8e8;
}

.ant-form-item-label {
  padding: 1px;
  padding-top: 3px;
  font-weight: 400;
  text-transform: capitalize;
}
/* App.css */
@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 1000; /* Ensure it is above other content */
    background-color: #2d2d2d; /* Match the sidebar background */
    transition: transform 0.3s ease; /* Smooth transition */
  }

  .sidebar.collapsed {
    transform: translateX(-100%); /* Hide the sidebar */
  }
}
