.thing-page {
  width: 100%;
  padding: 24px;
  background: linear-gradient(to bottom, #f8f9fa, #ffffff);
  border-radius: 12px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  transition: all 0.3s ease;
}

.thing-page .ant-typography {
  text-align: left;
  margin-bottom: 24px;
  color: #1a1a1a;
  font-weight: 600;
  transition: all 0.3s ease;
}

.thing-details {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.thing-details:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.thing-form .ant-form-item {
  margin-bottom: 16px;
  transition: all 0.2s ease;
}

.thing-form .ant-form-item-label > label {
  color: #666;
  font-weight: 500;
}

.thing-form .ant-input,
.thing-form .ant-select-selector,
.thing-form .ant-picker {
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  transition: all 0.3s ease;
}

.thing-form .ant-input:hover,
.thing-form .ant-select-selector:hover,
.thing-form .ant-picker:hover {
  border-color: #40a9ff;
}

.thing-form .ant-input:focus,
.thing-form .ant-select-selector:focus,
.thing-form .ant-picker:focus {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
}

.thing-actions {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}

.thing-actions .ant-btn {
  border-radius: 8px;
  height: 40px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.ql-container.ql-snow {
  border: none !important;
  border-radius: 0 0 8px 8px;
}

.ql-toolbar.ql-snow {
  border: none !important;
  background-color: #f8f9fa;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid #e8e8e8;
}

.collapsible-section {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.section-header {
  display: flex;
  align-items: center;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.section-header:hover {
  background: #f8f9fa;
}

p {
  margin: 0;
}
