.kanban-column {
  background: #f0f2f5;
  padding: 16px;
  border-radius: 4px;
  min-height: 500px;
}

.kanban-title {
  text-align: center;
  margin-bottom: 16px;
}

.kanban-item {
  margin-bottom: 8px;
}

.kanban-item .ant-card {
  cursor: grab;
}

.dragging-over {
  background-color: #e6f7ff;
}

.dragging {
  opacity: 0.8;
  background-color: #e6f7ff;
}
