.ant-menu-item-selected {
  background-color: #ffffff6e !important;
  color: #1f1f1f !important;
  border-radius: 0 0 !important;
  font-weight: 600 !important;
}

.ant-menu-item {
  padding: 8px !important;
  height: 28px !important;
  border-radius: 8px !important;
  color: #ffffff !important;
}

.ant-menu-submenu-title {
  color: #ffffff !important;
  font-weight: 800 !important;
  margin-left: 0px !important;
  padding-left: 10px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

ant-menu-submenu-arrow {
  color: #ffffff !important;
}
