.add-object-type-page {
  width: 100%;
  padding: 24px;
  background: #fff;
}

.add-object-type-page .ant-typography {
  text-align: center;
  margin-bottom: 24px;
}

.add-object-type-page .ant-space {
  display: flex;
  justify-content: flex-start;
}

.add-object-type-page .ant-form-item {
  margin-bottom: 8px;
}
