.ce-block__content,
.ce-toolbar__content {
  max-width: calc(100% - 100px);
}

.ce-toolbar__content {
  margin: 0 auto;
}

.ce-paragraph {
  margin: 0;
  padding: 0;
}

input:focus {
  outline: none;
}
