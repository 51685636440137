@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Main container styles */
.things-container {
  padding: 24px;
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  margin: 16px;
  animation: slideIn 0.3s ease-out;
}

/* Search and filters section */
.search-filters-section {
  margin-bottom: 24px;
  padding: 24px;
  background: linear-gradient(to right, #f8f9fa, #ffffff);
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.03);
  animation: fadeIn 0.4s ease-out;
}

.search-add-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Table styles */
.ant-table {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

.ant-table:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.ant-table-thead > tr > th {
  background: linear-gradient(145deg, #f8f9fa 0%, #f2f2f2 100%);
  font-weight: 600;
  padding: 18px 16px;
  border-bottom: 2px solid #eaeaea;
  transition: background-color 0.3s ease;
}

.ant-table-thead > tr > th:hover {
  background: linear-gradient(145deg, #f2f2f2 0%, #e9e9e9 100%);
}

.ant-table-tbody > tr > td {
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-tbody > tr:hover > td {
  background: linear-gradient(to right, #f8f9fa, #ffffff);
  transform: scale(1.001);
  transition: all 0.3s ease;
}

.ant-table-tbody > tr {
  transition: transform 0.3s ease;
}

.ant-table-tbody > tr:hover {
  transform: translateX(4px);
}

.ant-table-pagination {
  margin: 16px 24px;
}

/* Filter tags */
.filter-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.ant-tag {
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.ant-tag:hover {
  opacity: 0.8;
}

/* Input and button styles */
.ant-input-search {
  width: 300px;
  border-radius: 6px;
}

.ant-input-prefix {
  margin-right: 8px;
  color: #bfbfbf;
}

.ant-btn {
  border-radius: 8px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
  border: 1px solid #eaeaea;
}

.ant-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.ant-btn-primary {
  background: linear-gradient(145deg, #1890ff 0%, #096dd9 100%);
  border: none;
}

.ant-btn-primary:hover {
  background: linear-gradient(145deg, #40a9ff 0%, #1890ff 100%);
}

/* Delete button styles */
.delete-button {
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.45);
}

/* Delete button styles when items are selected */
.delete-button.has-selected {
  background: linear-gradient(145deg, #ff4d4f 0%, #cf1322 100%) !important;
  border: none !important;
  color: white !important;
}

.delete-button.has-selected:hover {
  background: linear-gradient(145deg, #ff7875 0%, #ff4d4f 100%) !important;
  color: white !important;
  opacity: 0.9;
}

.delete-button.has-selected:disabled {
  background: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
}

/* Modal styles */
.ant-modal {
  border-radius: 8px;
  overflow: hidden;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0;
  padding: 16px 24px;
  background: #fafafa;
}

.ant-modal-footer {
  border-radius: 0 0 8px 8px;
  padding: 16px 24px;
  background: #fafafa;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .things-container {
    padding: 16px;
    margin: 8px;
  }
  
  .ant-input-search {
    width: 100%;
  }
}
